@charset 'UTF-8';

// 隣接する要素の余白を設定
//


// 段落
//
p + p {
  margin-top: map-get( $p_margin_array, sm );
  @include media(md) {
    margin-top: map-get( $p_margin_array, md );
  }
  @include media(lg) {
    margin-top: map-get( $p_margin_array, lg );
  }
}



// button
//
p + .button,
.button + p,
img + .button,
.button + img,
.img + .button,
.button + .img {
  margin-top: map-get( $button_margin_array, sm );
  @include media(md) {
    margin-top: map-get( $button_margin_array, md );
  }
  @include media(lg) {
    margin-top: map-get( $button_margin_array, lg );
  }
}



// image
//
p + img,
img + p,
.img + .img,
p + .img,
.img + p {
  margin-top: map-get( $img_margin_array, sm );
  @include media(md) {
    margin-top: map-get( $img_margin_array, md );
  }
  @include media(lg) {
    margin-top: map-get( $img_margin_array, lg );
  }
}



// table
//
table + table,
p + table,
table + p,
.table + .table,
p + .table,
.table + p {
  margin-top: map-get( $table_margin_array, sm );
  @include media(md) {
    margin-top: map-get( $table_margin_array, md );
  }
  @include media(lg) {
    margin-top: map-get( $table_margin_array, lg );
  }
}



// alert
//
.alert + .alert,
p + .alert,
.alert + p,
.img + .alert,
.alert + .img {
  margin-top: map-get( $alert_margin_array, sm );
  @include media(md) {
    margin-top: map-get( $alert_margin_array, md );
  }
  @include media(lg) {
    margin-top: map-get( $alert_margin_array, lg );
  }
}



// block
//
.block + .block,
p + .block, .block + p,
.img + .block, .block + .img {
  margin-top: map-get( $block_margin_array, sm );
  @include media(md) {
    margin-top: map-get( $block_margin_array, md );
  }
  @include media(lg) {
    margin-top: map-get( $block_margin_array, lg );
  }
}



// pre
//
p + pre {
  margin-top: map-get( $p_margin_array, sm ) * .5;
  @include media(md) {
    margin-top: map-get( $p_margin_array, md ) * .5;
  }
  @include media(lg) {
    margin-top: map-get( $p_margin_array, lg ) * .5;
  }
}

pre + p {
  margin-top: map-get( $p_margin_array, sm );
  @include media(md) {
    margin-top: map-get( $p_margin_array, md );
  }
  @include media(lg) {
    margin-top: map-get( $p_margin_array, lg );
  }
}
