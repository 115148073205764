@charset 'UTF-8';

table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  margin: 0;
  padding: 0;

  th, td {
    border: 1px solid $table_border_color;
  }

  th {
    background: $table_th_background_color;
    padding: .3em 1em;
  }
  td {
    background: $table_td_background_color;
    overflow-wrap : break-word;
    padding: .6em 1em;
  }
}



// th block
//
.th-block {
  border-bottom: 1px solid $table_border_color;
  @include media(md) {
    border: none;
  }
  th, td {
    display: block;
    @include media(md) {
      display: table-cell;
    }
  }
  th {
    border-bottom: none;
    width: 100%;
    @include media(md) {
      border-bottom: 1px solid $table_border_color;
      width: 15%;
    }
  }
  td {
    border-bottom: none;
    @include media(md) {
      border-top: 1px solid $table_border_color;
      border-bottom: 1px solid $table_border_color;
    }
  }
}

// スクロールを出すためのwrapper
//
.table-scroll-wrapper {
  overflow-x: auto;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  @include media(md) {
    white-space: normal;
  }
}

 // 会社概要
table.company-profile {
  th, td {
    background: transparent;
    border: none;
    display: block;
    @include media(md) {
      border-bottom: 1px solid $table_border_color;
      display: table-cell;
      vertical-align: middle;
    }
  }

  th {
    color: $gray-700;
    font-size: 13px;
    width: 100%;
    span {
      color: $gray-500;
      display: block;
      font-weight: 400;
      font-size: 12px;
    }
    @include media(md) {
      width: 30%;
    }
    .profile-heading {
      display: inline-block;
      position: relative;
      &:before {
        background: $fourth-color;
        content: '';
        display: inline-block;
        width: 36px;
        height: 2px;
        position: absolute;
        bottom: -12px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }

  td {
    padding: 16px 0 32px;
    text-align: center;
    @include media(md) {
      padding: 44px 0 44px 16px;
      text-align: left;
    }
  }
}
