@charset 'UTF-8';

// variables
$current_class_name: '.current';
$current_view_class_name: '.current-view';



.tab-wrapper {
  width: 100%;
}



ul.tab-label {
  border-bottom: 1px solid $gray-300;
  display: flex;
  flex-flow: row wrap;

  li {
    border-top: 1px solid $gray-300;
    border-left: 1px solid $gray-300;
    flex: 1 0 auto;
    position: relative;

    &:last-child {
      border-right: 1px solid $gray-300;
    }

    .tab-label-item {
      background: $gray-200;
      cursor: pointer;
      color: $gray-400;
      display: block;
      padding: 1em 0;
      text-align: center;
      &:hover {
        color: $gray-600;
      }
      &#{$current_class_name} {
        background: $white-color;
        color: $body_text_color;
      }
    }
  }
}



.tab-contents {
  border-left: 1px solid $gray-300;
  border-right: 1px solid $gray-300;
  border-bottom: 1px solid $gray-300;

  .tab-contents-item {
    display: none;
    padding: 1em;

    &#{$current_view_class_name} {
      display: block;
      animation: show .4s linear 0s;
    }
  }
}
