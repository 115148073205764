input,
button,
select,
textarea {
  appearance: none;
  background: transparent;
  border: none;
  border-radius: 0;
  font: inherit;
  margin: 0;
  outline: none;
}

textarea {
  resize: vertical;
}

input[type='submit'],
input[type='button'],
button,
select {
  cursor: pointer;
}

select::-ms-expand {
  display: none;
}

// iOSのフォーカス時のズームも無効にする
.formworks {
  input[type='text'],
  input[type='radio'],
  input[type='checkbox'],
  textarea,
  select,
  option {
    font-size: $formworks-font-size;
    //transform: scale(0.875);
    //transform-origin: 0 50%;
  }
  input[type='checkbox'] {
    appearance: none;
    -webkit-appearance: none;
  }
}
