@charset 'UTF-8';

// 背景画像を表示するラッパー
//
.local-background-wrapper {
  position: relative;
  width: 100%;
  z-index: 0;
  margin-top: map-get( $section_gutter_array, sm );
  margin-bottom: map-get( $section_gutter_array, sm );
  @include media(md) {
    margin-top: map-get( $section_gutter_array, md );
    margin-bottom: map-get( $section_gutter_array, md );
  }
  @include media(lg) {
    margin-top: map-get( $section_gutter_array, lg );
    margin-bottom: map-get( $section_gutter_array, lg );
  }
}

.local-background-inner-text {
  color: $white-color;
  font-weight: 700;
  text-shadow: 0px 0px 6px rgba( 0,0,0, 0.5 );
  position: absolute;

  &._whois {
    display: flex;
    align-items: center;
    bottom: 10%;
    right: 1em;
    font-size: 1.125em;
    @include media(md){
      font-size: 2.125em;
      line-height: 1.4;
    }
    @include media(lg){
      font-size: 2.725em;
    }
    span {
      display: block;
      margin-right: .5em;
    }
    img {
      display: block;
      width: 40px;
      height: 40px;
      @include media(md){
        width: 68px;
        height: 68px;
      }
      @include media(lg){
        width: 88px;
        height: 88px;
      }
    }
  }

  &._greeting {
    top: 74%;
    right: 10%;
    font-size: 1.4em;
    @include media(md){
      font-size: 3.2em;
    }
  }

  &._about {
    top: 10%;
    right: 10%;
    font-size: 1.8em;
    text-align: right;
    white-space: nowrap;
    @include media(md){
      right: 5%;
      font-size: 3.6em;
    }
  }

  &.__error {
    top: 10%;
    left: .5em;
    font-size: 1.8em;
    @include media(md){
      font-size: 3.6em;
    }
  }
}
