@charset 'UTF-8';

// フォームの上部のご案内や注意文章

.form-information-wrapper {
  background: $white-color;
  margin-bottom: map-get( $header-padding-bottom-array, sm );
  padding: 1.5em;
  @include media(md) {
    margin-bottom: map-get( $header-padding-bottom-array, md );
  }
  @include media(lg) {
    margin-bottom: map-get( $header-padding-bottom-array, lg );
  }
}

.form-notice {
  margin-top: 2em;
}

.form-notice-heading {
  border: 2px solid $red-color;
  border-radius: 2em;
  color: $red-color;
  display: inline-block;
  font-size: .825em;
  line-height: 1;
  margin-bottom: .725em;
  padding: .6em 1em calc( .6em - 2px );
}

ul.form-notice-list {
  font-size: .9em;
  padding-left: 1em;
  li {
    text-indent: -1em;
    &:nth-of-type(n+2){
      margin-top: .5em;
    }
    &:before {
      content: '※';
      font-family: serif;
    }
  }
}



// フォームの項目名表示

.form-item-name {
  display: flex;
  align-items: center;
  justify-content: space-between;

  // 項目名
  .title {
    color: inherit;
    font-size: 1em;
    font-weight: 300;
  }

  // 必須と任意
  .optional,
  .required {
    font-size: .875em;
    font-weight: 700;
    line-height: 1;
    padding: .4em .4em calc( .4em - 1px );
  }
  // 任意
  .optional {
    background: #339966;
    color: #fff;
  }
  // 必須
  .required {
    background: #b0120a;
    color: #fff;

  }
}



// jquery.validate.min.js エラー表示用
.validate-error {
  .error {
    color: $red-color;
    display: inline-block;
    font-family: sans-serif;
    font-size: .85em;
    font-weight: 700;
    margin-top: .5em;
  }
}



.sm-mb-24 {
  margin-bottom: 24px;
  @include media(md){
    margin-bottom: 0;
  }
}



// 日付をクリアする
.date-clear-btn {
  background: #f8f9fa;
  border: 1px solid #adb5bd;
  border-radius: 3px;
  cursor: pointer;
  color: #333;
  display: inline-block;
  font-weight: 500;
  font-size: 12px;
  height: 28px;
  padding: 0 12px;
  text-align: center;
  &:hover {
    background: #e9ecef;
  }
}



// グループの見出し
.group-heading {
  font-size: 1.125em;
  font-weight: 700;
  margin-bottom: .5em;
}
