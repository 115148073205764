@charset 'UTF-8';

.formworks-container {
  border-top: $formworks-border;
  display: block;
  @include media(md){
    display: flex;
  }
  &.last {
    border-bottom: $formworks-border;
  }
}

// フォームの項目名表示
.formworks-title {
  background: $formworks-title-background-color;
  border-left: $formworks-border;
  border-right: $formworks-border;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: .6em 1em;
  width: 100%;
  @include media(md){
    width: 26rem;
  }

  // 項目名
  .title {
    color: inherit;
    font-size: .875em;
    font-weight: 500;
  }

  // 必須と任意
  .optional,
  .required {
    font-size: .8em;
    font-weight: 700;
    line-height: 1;
    padding: .4em .4em;
  }
  // 任意
  .optional {
    background: #339966;
    color: #fff;
  }
  // 必須
  .required {
    background: #b0120a;
    color: #fff;
  }
}

.formworks-items{
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}

.value-optional,
.value-required {
  font-size: .8375em;
  font-weight: 700;
  line-height: 1;
  padding: .4em .4em calc( .4em - 1px );
  border-radius: 0.25em;
  white-space: nowrap;
  margin-right: 1em;
}

// 任意
.value-optional {
  background: #339966;
  color: #fff;
  display: inline-block;
}
// 必須
.value-required {
  background: #b0120a;
  color: #fff;
  display: inline-block;
}

// フォーム入力表示
.formworks-value {
  background: $formworks-value-background-color;
  border-left: $formworks-border;
  border-right: $formworks-border;
  padding: 1em;
  @include media(md){
    border-left: none;
    flex: 1;
  }
}
