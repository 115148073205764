@charset 'UTF-8';

// input
.formworks-input {
  background: $formworks-background-color;
  border-bottom: $border-animation-thickness solid $formworks-border-color;
  box-shadow: none;
  color: inherit;
  font-family: $font-family;
  font-size: 1em;
  line-height: 1;
  padding: 1.125em 0 .4em;
  position: relative;
  width: 100%;
  transition: border-color $formworks-transition, background $formworks-transition;
  &:not(.date):read-only {
    border-bottom: none;
    padding: 0;
  }
}



// textarea
//
textarea {
  border: $border-animation-thickness solid $formworks-border-color;
  font-family: $font-family;
  line-height: 1.6;
  height: auto;
  padding: 1em;
  resize: vertical;
  width: 100%;
  // プレースホルダー
  &::placeholder {
    font-size: .875em;
    transition: color $transition;
  }
  &::-moz-placeholder {
    color: $placeholder-color;
    opacity: 1;
  }
  &:-ms-input-placeholder {
    color: $placeholder-color;
  }
  &::-webkit-input-placeholder {
    color: $placeholder-color;
  }
  &::-ms-expand {
    background: $formworks-background-color;
    border: 0;
  }
  // フォーカス
  &:focus {
    background: $formworks-background-color;
    border-color: $placeholder-after-color;
    outline: 0;
    &::placeholder {
      color: transparent;
    }
  }
  &:read-only {
    border: none;
    padding: 0;
    resize: none;
  }
}


// radio button
// checkbox
$formworks-radio-checked-color: $base-color;
$formworks-radio-border-color: rgba( 0, 0, 0, 0.54 );
$formworks-radio-size: 16px;
$formworks-radio-checked-size: 8px;
$formworks-radio-ripple-size: 12px;

@keyframes ripple {
  0% {
    box-shadow: 0px 0px 0px 1px rgba( 0, 0, 0, 0.0 );
  }
  50% {
    box-shadow: 0px 0px 0px $formworks-radio-ripple-size rgba( 0, 0, 0, 0.1 );
  }
  100% {
    box-shadow: 0px 0px 0px $formworks-radio-ripple-size rgba( 0, 0, 0, 0 );
  }
}

@keyframes rippleRev {
  0% {
    box-shadow: 0px 0px 0px $formworks-radio-ripple-size rgba( 0, 0, 0, 0 );
  }
  50% {
    box-shadow: 0px 0px 0px $formworks-radio-ripple-size rgba( 0, 0, 0, 0.1 );
  }
  100% {
    box-shadow: 0px 0px 0px 1px rgba( 0, 0, 0, 0.0 );
  }
}

// radio button
.formworks-radio {
  display: flex;

  & + & {
    margin-top: .8em;
  }

  .radio-item {
    display: inline-block;
  }

  .radio-item + .radio-item {
    margin-left: $formworks-font-size + 8px;
  }

  input[type="radio"] {
    &:checked + label:before {
      border-color: $formworks-radio-checked-color;
      animation: ripple 0.2s linear forwards;
    }
    &:checked + label:after {
      transform: scale(1);
    }
  }

  label {
    display: inline-block;
    font-size: $formworks-font-size;
    line-height: $formworks-radio-size;
    min-height: $formworks-radio-size;
    position: relative;
    padding-left: $formworks-radio-size + 6px;
    margin-bottom: 0;
    cursor: pointer;
    &:before, &:after {
      position: absolute;
      content: '';
      border-radius: 50%;
      transition: all .3s ease;
      transition-property: transform, border-color;
    }
    &:before {
      left: 0;
      top: 0;
      width: $formworks-radio-size;
      height: $formworks-radio-size;
      border: 2px solid $formworks-radio-border-color;
    }
    &:after {
      top: $formworks-radio-size / 2 - $formworks-radio-checked-size / 2;
      left: $formworks-radio-size / 2 - $formworks-radio-checked-size / 2;
      width: $formworks-radio-checked-size;
      height: $formworks-radio-checked-size;
      transform: scale(0);
      background: $formworks-radio-checked-color;
    }
  }
}

// checkbox
.formworks-checkbox {
  display: flex;

  & + & {
    margin-top: .8em;
  }

  .checkbox-item {
    display: inline-block;
  }

  .checkbox-item + .checkbox-item {
    margin-left: $formworks-font-size + 8px;
  }

  input[type="checkbox"] + label {
    cursor: pointer;
    display: inline-block;
    position: relative;
    user-select: none;
    line-height: 18px;
    padding-left: 28px;
      &:before {
        content: '';
        display: inline-block;
        width: 18px;
        height: 18px;
        border: 2px solid rgba( 0, 0, 0, 0.54 );
        transition: all .12s, border-color .08s;
        position: absolute;
        left: 0;
        animation: rippleRev 0.2s linear forwards;
    }
    &:after {
      content: '';
      display: inline-block;
      height: 12px;
      border: 1px solid $base-color;
      position: absolute;
      left: 6px;
      top: 1px;
      width: 7px;
      border-radius: 0;
      border-top-color: transparent;
      border-left-color: transparent;
      border-width: 2px;
      transform: rotate(45deg);
      opacity: 0;
      transition: all 0.12s;
    }
  }
  // checked
  input[type="checkbox"]:checked + label {
    color: #333;
    &:after {
      content: '';
      opacity: 1;
    }
    &::before{
      content: '';
      animation: ripple 0.2s linear forwards;
      border: 2px solid $base-color;
    }
  }
}

// Select
//
// select
.formworks-select {
  display: inline-block;
  position: relative;
  select {
    background: $white-color;
    border: 1px solid $gray-400;
    border-radius: 4px;
    color: inherit;
    cursor: pointer;
    display: block;
    font-size: $formworks-font-size;
    line-height: 1;
    padding: .8em 2.5em .8em 1em;
    position: relative;
    outline: none;
    overflow: hidden;
  }
  // 矢印
  &:after {
    border: 2px solid $gray-500;
    border-right: 0;
    border-top: 0;
    content: '';
    display: block;
    margin-top: -1px;
    pointer-events: none;
    position: absolute;
    top: 50%;
    right: .8em;
    transform: rotate(-45deg) translateY(-50%);
    width: 7px;
    height: 7px;
    @include media(lg){
      margin-top: -2px;
      width: 8px;
      height: 8px;
    }
  }
}



// プライバシーポリシーに同意する
//
.privacy-checkbox-wrapper {
  margin: 2em auto 0;
  width: 100%;
  @include media(md){
    width: 360px;
  }
}

.privacy-checkbox-container {
  display: block;
  margin-top: -1em;
}

.privacy-link-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.privacy-checkbox-inner {
  margin-top: -1.2em;
  text-align: center;
  input[type="checkbox"] + label {
    background: #fff;
    box-shadow: 0px 0px 4px -1px rgba( 0, 0, 0, .2 );
    color: #adb5bd;
    cursor: pointer;
    display: block;
    font-size: 14px;
    line-height: 1;
    position: relative;
    padding: 14px 28px 11px;
    user-select: none;
    span {
      display: inline-flex;
      align-items: center;
      &:before {
        content: '';
        display: inline-block;
        width: 20px;
        height: 20px;
        border: 1px solid #6cc0e5;
        margin-right: 12px;
        opacity: .6;
        transition: all .12s, border-color .08s;
      }
    }

  }
  // checked
  input[type="checkbox"]:checked + label {
    color: #333;
    span {
      &:before {
        width: 10px;
        //transform: translateY(-50%);
        border-radius: 0;
        opacity: 1;
        border-top-color: transparent;
        border-left-color: transparent;
        border-width: 2px;
        transform: translateY( -4px ) rotate(45deg);
      }
    }

  }
}

.privacy-link {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 8px;
  img {
    display: block;
    margin-right: 4px;
    width: 14px;
    height: 14px;
  }
  a {
    color: #333;
    font-size: 12px;
    &:hover {
      text-decoration: underline;
    }
  }
}
