@charset 'UTF-8';

// border アニメーション
.border-animation {
  &::after {
    background: $border-animation-color;
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: $border-animation-thickness;
    z-index: 10;
    transform-origin: center;
    transform: scaleX(0);
    transition: all .3s ease;
  }
  #{$formworks-focus-in-class-name} &,
  #{$formworks-focus-fixed-class-name} & {
    &::after {
      transform: scaleX(1);
    }
  }
  &._textarea {
    &::after {
      bottom: 4px;
      @include media(lg){
        bottom: 6px;
      }
    }
  }
}



// Placeholder
.placeholder-parent {
  position: relative;
  width: 100%;
}

.placeholder-item {
  color: $placeholder-color;
  font-size: .875em;
  pointer-events: none;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  transform-origin: 0 50%;
  transition: .2s;

  #{$formworks-focus-in-class-name} &,
  #{$formworks-focus-fixed-class-name} & {
    color: $placeholder-after-color;
    font-weight: 700;
    top: 0;
    transform: scale(.8);
    @include media(md){
      top: -6px;
    }
  }
}



// カレンダーアイコンの表示
.icon-calendar {
  position: absolute;
  top: 50%;
  right: 32px;
  transform: translateY(-50%);
  width: 16px;
  height: 16px;
  pointer-events: none;
  &:before {
    content: '';
    background-image: url( ../../images/calendar.svg );
    background-size: contain;
    background-repeat: no-repeat;
    display: inline-block;
    width: 100%;
    height: 100%;
  }
  @include media(lg){
    width: 20px;
    height: 20px;
  }
}

// カレンダーの value をクリアするアイコン
.icon-delete {
  cursor: pointer;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  width: 24px;
  height: 24px;
  opacity: .6;
  transition: opacity $transition;
  &:before {
    content: '';
    background-image: url( ../../images/dismiss_circle.svg );
    background-size: contain;
    background-repeat: no-repeat;
    display: inline-block;
    width: 100%;
    height: 100%;
  }
  &:hover {
    opacity: .8;
  }
}
