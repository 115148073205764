@charset 'UTF-8';

// formworks grid
//

// Variables

// formworks-grid 内のカラム数
$formworks-grid-columns: 12 !default;

// カラム同士の間隔（指定：px）
$formworks-grid-gutter-array: (
  sm: 12px,
  md: 12px,
  lg: 16px
) !default;



.formworks-grid {
  display: flex;
  flex-flow: row wrap;
  margin: ( - (map-get($formworks-grid-gutter-array, sm) / 2) );
  @include media(md) {
    margin: ( - (map-get($formworks-grid-gutter-array, md) / 2) );
  }
  @include media(lg) {
    margin: ( - (map-get($formworks-grid-gutter-array, lg) / 2) );
  }
  // col
  //
  .col {
    padding: map-get($formworks-grid-gutter-array, sm) / 2;
    @include media(md) {
      padding: map-get($formworks-grid-gutter-array, md) / 2;
    }
    @include media(lg) {
      padding: map-get($formworks-grid-gutter-array, lg) / 2;
    }
  }
}



// 揃える
//
.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}
// 縦組み  旧Android iOS6以前は未対応
.justify-end-vertical {
  align-content: flex-end;
}

.justify-center-vertical {
  align-content: center;
}

.justify-between-vertical {
  align-content: space-between;
}

.justify-around-vertical {
  align-content: space-around;
}

// 子要素の天地の揃え
// align-items は親要素 align-self は子要素
//
.align-top {
  align-items: flex-start;
}

.align-self-top {
  align-self: flex-start;
}

.align-end {
  align-items: flex-end;
}

.align-self-end {
  align-self: flex-end;
}

.align-center {
  align-items: center;
}

.align-self-center {
  align-self: center;
}

.align-stretch {
  align-items: stretch;
}

.align-self-stretch {
  align-self: stretch;
}



// order
.sm-order-0 {
  order: 0;
  @include media(md){
    order: 1;
  }
}

.sm-order-1 {
  order: 1;
  @include media(md){
    order: 0;
  }
}
