@charset 'UTF-8';

// メディアクエリの書き出し
//
@mixin media( $data )
{
  $key: map-get( $breakpoints, $data );
  @media screen and ( min-width: convert_em( $key ) ) {
    @content;
  }
}



// grid
//
@mixin make_grid( $width )
{
  flex: 0 0 $width;
  width: $width;
  max-width: $width;
}



// icon
//
@mixin icon_default
{
  font-family: $icon_font_family;
  content: map-get( $icon_type_array, 'default' );
  color: inherit;
  display: inline-block;
  font-weight: 900;
  text-align: center;
}



// fluid width
//
@mixin width_wrapper( $width_array, $padding_array )
{
  @if ( $fluid_sm == false ) {
    min-width: calc( #{map-get( $width_array, sm )} + #{map-get( $padding_array, sm ) * 2} );
  }
  @if ( $fluid_md == false ) {
    @include media(md) {
      min-width: calc( #{map-get( $width_array, md )} + #{map-get( $padding_array, md ) * 2} );
    }
  }
  @if ( $fluid_lg == false ) {
    @include media(lg) {
      min-width: calc( #{map-get( $width_array, lg )} + #{map-get( $padding_array, lg ) * 2} );
    }
  }
}

@mixin width_container( $width_array )
{
  @if ( $fluid_sm == true ) {
    width: 100%;
    max-width: map-get( $width_array, sm );
  } @else {
    width: map-get( $width_array, sm );
  }
  @if ( $fluid_md == true ) {
    @include media(md) {
      max-width: map-get( $width_array, md );
    }
  } @else {
    @include media(md) {
      width: map-get( $width_array, md );
    }
  }
  @if ( $fluid_lg == true ) {
    @include media(lg) {
      max-width: map-get( $width_array, lg );
    }
  } @else {
    @include media(lg) {
      width: map-get( $width_array, lg );
    }
  }
}
