@charset 'UTF-8';

// Color
$base  : $base_color !default;
$first : $first_color !default;
$second: $second-color !default;
$third : $third-color !default;
$fourth: $fourth-color !default;
$five  : $five-color !default;

$white : $white_color !default;
$black : $black_color !default;
$red   : $red_color !default;
$yellow: $yellow_color !default;
$orange: $orange_color !default;
$green : $green_color !default;
$blue  : $blue_color !default;
$pink  : $pink_color !default;

// Spacer
$base-spacer: 1em !default;

// Base box shadow
$base-box-shadow: 0 .5rem 1rem rgba(0,0,0, .15) !default;

// Base transition
$base-transition: .2s ease-out;

// Characters which are escaped by the escape-svg function
$escaped-characters: (
  ('<', '%3c'),
  ('>', '%3e'),
  ('#', '%23'),
  ('(', '%28'),
  (')', '%29'),
) !default;
