@charset 'UTF-8';

// Font family
//
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;500;700;800;900&display=swap');

@font-face {
  font-family: 'Original BIZ UDPGothic';
  src: local('BIZ-UDPGothic');
  font-weight: 400;
}
@font-face {
  font-family: 'Original BIZ UDPGothic';
  src: local('BIZ-UDPGothic-Bold');
  font-weight: bold;
}

//$font-family: 'YakuHanJPs', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Hiragino Sans', 'Noto Sans CJK JP', 'Original Yu Gothic', 'Yu Gothic', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Sans Emoji';
//$font_family: 'YakuHanJPs', -apple-system  BlinkMacSystemFont, 'Helvetica Neue', 'Segoe UI', 'Hiragino Kaku Gothic ProN', 'メイリオ', Meiryo, arial, sans-serif;
//$font-family: 'YakuHanJPs', -apple-system, Robot, 'Helvetica Neue', 'Helvetica', 'Hiragino Sans', 'Noto Sans CJK JP', 'メイリオ', Meiryo, Arial, sans-serif;
$font-family: 'YakuHanJPs', 'Original BIZ UDPGothic', 'Poppins', 'Jun 501', -apple-system, Robot, 'Helvetica Neue', 'Helvetica', 'Hiragino Sans', 'Noto Sans CJK JP', 'メイリオ', Meiryo, Arial, sans-serif;
//$font-family: 'Jun 501', sans-serif;
$TBUD-G-E: 'Original BIZ UDPGothic', 'TBUDGothic E', sans-serif;



// body の text color
//
$body_text_color: #333 !default;



// body の background-color
//
$background_body: #f5fdfe !default;



// body の文字サイズ
//
$base_font_size: 1rem !default; // default font size 1rem = 10px

$base_font_size_array: (
  sm: $base_font_size * 1.4,
  md: $base_font_size * 1.5,
  lg: $base_font_size * 1.6,
) !default;



// body の letter_spacing（単位は自由）
//
$body_letter_spacing: (
  sm: 0,
  md: 0,
  lg: 0
) !default;



// body の line-height
//
$base_line_height: (
  sm: 1.4,
  md: 1.6,
  lg: 1.6
) !default;



// リンクのテキストカラー
//
$link_color: #0033cc !default;



// アンダーライン
//
$link_decoration: none !default;



// ホバー時のテキストカラー
//
$link_hover_color: darken( $link_color, 15% ) !default;



// ホバー時のアンダーライン
//
$link_hover_decoration: none !default;



// horizontal line
//

// ボーダーの種類
//
$hr_border: 1px solid $gray-400 !default;



// ボーダー上下の間隔
//
$hr_margin: (
  'sm' : ( 'top' : .5em, 'bottom' : 1em ),
  'md' : ( 'top' : .5em, 'bottom' : 1em ),
  'lg' : ( 'top' : .5em, 'bottom' : 1em )
) !default;



// fonts directory path
//
$font_directory_path: '../webfonts';
