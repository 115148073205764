@charset 'UTF-8';

.label {
  background: multi-array( $label_colors_array, 'base', 'bg' );
  border: $label_border solid transparent;
  border-radius: $label_border_radius;
  color: multi-array( $label_colors_array, 'base', 'color' );
  display: inline-block;
  font-size: $label_font_size;
  line-height: 1 !important;
  padding: map-get( $label_padding_vertical_array, sm ) map-get( $label_padding_horizontal_array, sm );
  text-align: center;
  position: relative;
  @include media(md) {
    padding: map-get( $label_padding_vertical_array, md ) map-get( $label_padding_horizontal_array, md );
  }
  @include media(lg) {
    padding: map-get( $label_padding_vertical_array, lg ) map-get( $label_padding_horizontal_array, lg ) calc( #{map-get( $label_padding_vertical_array, lg )} + 1px );
  }
  // bacground color pattern
  @each $name, $value in $label_colors_array {
    &.#{$name} {
      background: map-get( $value, 'bg' );
      color: map-get( $value, 'color' );
    }
  }
  // Color Pattern gray & Basic color
  @each $name, $value in $basic_colors_array {
    &.#{$name} {
      background: $value;
      @if ( $name == gray-900 or $name == gray-800 or $name == gray-700 or $name == gray-600 or $name == gray-500 or $name == 'black' or $name == 'red' or $name == 'blue' or $name == 'pink' ){
        color: $white-color;
      } @else {
        color: inherit;
      }
    }
  }

  &.outline {
    background: multi-array( $label_outline_colors_array, 'base', 'bg' );
    border: $label_border solid multi-array( $label_outline_colors_array, 'base', 'border' );
    color: multi-array( $label_outline_colors_array, 'base', 'color' );

    // border color pattern
    @each $name, $value in $label_outline_colors_array {
      &.#{$name} {
        border: $label_border solid map-get( $value, 'border' );
        color: map-get( $value, 'color' );
      }
    }
    // Color Pattern gray & Basic color
    @each $name, $value in $basic_colors_array {
      &.#{$name} {
        border: $label_border solid $value;
        color: $value;
      }
    }
  }

  &.expanded {
    display: block;
  }
}

span.label {
  margin-left: .5em;
  margin-right: 0;
  &.left {
    margin-left: 0;
    margin-right: .5em;
  }
}
