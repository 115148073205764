@charset 'UTF-8';

// 確認用モーダルウインドウ
//
.confirm-wrapper {
  background: $white-color;
  overflow-y: auto;
  padding: 4em 0;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  //animation: show .05s linear 0s;
}

.confirm-container {
  background: $white-color;
  margin: 0 auto;
  padding: 2em 1em;
  width: 90%;
  max-width: 640px;
}

.modal-form-container {
  margin: 0 auto;
  max-width: 375px;
  @include media(md){
    max-width: 548px;
  }
}

h4.confirm {
  border-bottom: 1px solid $gray-200;
  color: #5a7dad;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 24px;
  @include media(md){
    font-size: 22px;
    margin-bottom: 28px;
  }
}

ul.confirm {
  margin-bottom: 3.2em;
  > li {
    display: block;
    &:not(:last-child){
      margin-bottom: 2em;
      @include media(md){
        margin-bottom: 3em;
      }
    }
    @include media(md){
      display: flex;
      align-items: flex-start;
    }

    .head {
      border-left: 2px solid $base-color;
      border-bottom: 1px solid $gray-200;
      color: $gray-700;
      font-size: .8em;
      font-weight: 700;
      margin-bottom: 1em;
      padding: 4px 8px 6px;
      @include media(md){
        border-bottom: none;
        margin-bottom: 0;
        padding: 6px 8px;
        width: 16rem;
      }
    }
    .contents {
      flex: 1;
      padding-top: 4px;
      padding-left: 1em;
      @include media(md){
        padding-top: 6px;
        padding-left: 0;
      }
    }
  }
}

body.overflow {
  overflow: hidden;
}
