@charset 'UTF-8';

$login-font-size: 16px;



.login-wrapper {
  background-color: $base;
  position: relative;
  width: 100%;
  height: 100%;
}

.login-container {
  background-color: $white;
  border-radius: 6px;
  box-shadow: $base-box-shadow;
  min-width: 320px;
  overflow: hidden;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate( -50%, -50% );
  h1 {
    color: inherit;
    font-family: century-gothic, -apple-system, Robot, 'Helvetica Neue', 'Helvetica', Arial, sans-serif;
    font-size: 1.8rem;
    font-weight: 700;
    margin-bottom: 1rem;
    text-align: center;
  }
}

.login-header {
  background: $fourth;
  color: $white;
  display: block;
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 1;
  padding: .8rem 0;
  text-align: center;
}

.login-body {
  padding: 1rem 2rem 2rem;
}

.login-label {
  color: $base;
  font-size: 1.2rem;
  font-weight: 700;
  margin-bottom: .5rem;
}

.login-field {
  & + & {
    margin-top: 2rem;
  }
}



.login-input {
  background: $white;
  border: 2px solid $gray-400;
  border-radius: 4px;
  box-shadow: none;
  color: $gray-dark;
  font-size: $login-font-size;
  padding: 1rem 1.2rem;
  width: 100%;
  transition: border-color $base-transition, background $base-transition;
  // プレースホルダー
  &::placeholder {
    font-size: 1.6rem;
    transition: color $base-transition;
  }
  &::-moz-placeholder {
    color: $gray-400;
    opacity: 1;
  }
  &:-ms-input-placeholder {
    color: $gray-400;
  }
  &::-webkit-input-placeholder {
    color: $gray-400;
  }
  &::-ms-expand {
    background: $white;
    border: 0;
  }
  // フォーカス
  &:focus {
    background: $white;
    border-color: $base;
    box-shadow: 0 0 6px 1px rgba( $base, .075 );
    outline: 0;
    &::placeholder {
      color: transparent;
    }
  }
}



.login-btn-wrapper {
  display: block;
  margin-top: 3rem;
  text-align: center;
}

.login-btn {
  background-color: $fourth;
  border-radius: 2em;
  box-shadow: 0 3px 4px -2px #21253840;
  color: $white;
  cursor: pointer;
  display: inline-block;
  font-size: $login-font-size;
  font-weight: 700;
  line-height: 1.42875;
  text-align: center;
  -webkit-appearance: none;
  text-decoration: none;
  transition: background $base-transition, color $base-transition;
  vertical-align: middle;
  white-space: nowrap;
  padding: 1rem 2rem;
}



.login-validate-error {
  display: inline-block;
  margin-left: 1rem;
}

.login-error-message {
  animation: show .7s cubic-bezier(0.33, 1, 0.68, 1) 1 forwards;
  color: $red;
  font-size: 1.2rem;
  font-weight: 500;
}
