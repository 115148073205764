@charset 'UTF-8';

// Layout
//

// ボーダー
$formworks-border: 1px solid #ced4da;

// フォームの項目タイトルのバックグラウンドカラー
$formworks-title-background-color: #EBF7F8;

// フォームの項目のバックグラウンドカラー
$formworks-value-background-color: #fff;



// Form
//

// Form内のフォントサイズ
$formworks-font-size: 16px;

// form 要素内の背景色
$formworks-background-color: #fff;

// form 要素のボーダー色
$formworks-border-color: #ced4da;

// form で使用するアニメーションのトランジション
$formworks-transition: .2s ease-out;

// form 要素をフォーカスしたときに付与するクラス名
$formworks-focus-in-class-name: '.focus-in';
$formworks-focus-fixed-class-name: '.focus-fixed';

// ボーダーのアニメーションで変化するボーダー色
$border-animation-color: #333;

// ボーダーの太さ
$border-animation-thickness: 1px;

// プレースホルダーの色
$placeholder-color: #ced4da;

// プレースホルダーの変化する色
$placeholder-after-color: #339966;
