@charset 'UTF-8';

// burger-wrapper
.burger-wrapper {
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  position: relative;
  @include media(lg){
    display: none;
  }
}



// burger
//
.burger-icon {
  width: map-get( $burger_border_width_array, 'sm' );
  span {
    background: $burger_border_color;
    display: block;
    width: 100%;
    height: $burger_border_thickness; // 太さ
    transition: transform $transition, opacity $transition;
    &:nth-of-type(2) {
      margin: map-get( $burger_border_margin_array, 'sm' ) 0; // ボーダー同士の間隔
    }
  }
  @include media(md) {
    width: map-get( $burger_border_width_array, 'md' );
    span:nth-of-type(2) {
      margin: map-get( $burger_border_margin_array, 'md' ) 0; // ボーダー同士の間隔
    }
  }
  @include media(lg) {
    width: map-get( $burger_border_width_array, 'lg' );
    span:nth-of-type(2) {
      margin: map-get( $burger_border_margin_array, 'lg' ) 0; // ボーダー同士の間隔
    }
  }
  // OPEN
  #{$burger_open_class} & {
    background: transparent;
    span {
      &:nth-of-type(1) {
        transform: translateY(6px) rotate(-45deg);
      }
      &:nth-of-type(2) {
        opacity: 0;
      }
      &:nth-of-type(3) {
        transform: translateY(-6px) rotate(45deg);
      }
    }
    @include media(md) {
      span {
        &:nth-of-type(1) {
          transform: translateY(6px) rotate(-45deg);
        }
        &:nth-of-type(2) {
          opacity: 0;
        }
        &:nth-of-type(3) {
          transform: translateY(-6px) rotate(45deg);
        }
      }
    }
    @include media(lg) {
      span {
        &:nth-of-type(1) {
          transform: translateY(6px) rotate(-45deg);
        }
        &:nth-of-type(2) {
          opacity: 0;
        }
        &:nth-of-type(3) {
          transform: translateY(-6px) rotate(45deg);
        }
      }
    }
  }
}
