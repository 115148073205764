@charset 'UTF-8';

// variable
$accordion_open_class_name: '.active';



.accordion-wrapper {
  width: 100%;
}



.accordion {
  .accordion-heading {
    background: $base-color;
    border-bottom: 1px solid darken( $base-color, 5% );
    color: $white-color;
    cursor: pointer;
    font-size: 1em;
    outline: none;
    margin: 0;
    padding: 1em;
    position: relative;
    transition: background $transition;
    width: 100%;
    &:hover {
      background: darken( $base-color, 5% );
    }
    // arrow
    &:after {
      border: 2px solid $white-color;
      border-right: 0;
      border-top: 0;
      content: '';
      display: block;
      pointer-events: none;
      position: absolute;
      top: 48%;
      right: 1em;
      transform: rotate(-45deg) translateY(-50%);
      width: 8px;
      height: 8px;
      @include media(lg){
        width: 10px;
        height: 10px;
      }
    }
    &#{$accordion_open_class_name} {
      &:after {
        border: 2px solid $white-color;
        border-left: 0;
        border-bottom: 0;
      }
    }
  }
  .accordion-contents {
    overflow: hidden;
    max-height: 0;
    transition: max-height $transition;
  }
  .accordion-contents-inner {
    background: $white-color;
    padding: 1em;
  }
}
