@charset 'UTF-8';

// variables
$formworks-btn-color-array: (
  'default': ( 'bg' : $base-color,   'color' : $white-color ),
  'first'  : ( 'bg' : $first-color,  'color' : $white-color ),
  'second' : ( 'bg' : $second-color, 'color' : $white-color ),
  'third'  : ( 'bg' : $third-color,  'color' : $white-color ),
  'fourth' : ( 'bg' : $fourth-color, 'color' : $white-color ),
  'five'   : ( 'bg' : $five-color,   'color' : $white-color )
) !default;

$formworks-btn-hover-color-array: (
  'default'   : ( 'bg' : darken($base-color, 10%),   'color' : $white-color ),
  'first'  : ( 'bg' : darken($first-color, 10%),  'color' : $white-color ),
  'second' : ( 'bg' : darken($second-color, 10%), 'color' : $white-color ),
  'third'  : ( 'bg' : darken($third-color, 10%),  'color' : $white-color ),
  'fourth' : ( 'bg' : darken($fourth-color, 10%), 'color' : $white-color ),
  'five'   : ( 'bg' : darken($five-color, 10%),   'color' : $white-color )
) !default;

// 角丸
$formworks-btn-border-radius: 4px !default;

// ボーダーの太さ
$formworks-btn-border: 2px !default;

// フォントサイズ
$formworks-btn-size: (
  sm: 14px,
  md: 14px,
  lg: 14px
) !default;

// ボタンの高さ
$formworks-btn-height: (
  sm: 40px,
  md: 40px,
  lg: 44px
) !default;

// ボタンの左右のパディング
$formworks-btn-padding: (
  sm: 16px,
  md: 16px,
  lg: 16px
) !default;

// ボタン同士の間隔
$formworks-btn-gutter: (
  sm: 8px,
  md: 12px,
  lg: 16px
) !default;


// ---------- ---------- ---------- ---------- ---------- ---------- ----------



// wrapper
.formworks-btn-wrapper {
  margin: 3em 0;
  position: relative;
}

// grid
.formworks-btn-grid {
  display: flex;
  flex-flow: row wrap;
  margin: ( - (map-get($formworks-btn-gutter, sm) / 2) );
  @include media(md){
    margin: ( - (map-get($formworks-btn-gutter, md) / 2) );
  }
  @include media(lg){
    margin: ( - (map-get($formworks-btn-gutter, lg) / 2) );
  }
  // col
  //
  .col {
    padding: map-get($formworks-btn-gutter, sm) / 2;
    @include media(md) {
      padding: map-get($formworks-btn-gutter, md) / 2;
    }
    @include media(lg) {
      padding: map-get($formworks-btn-gutter, lg) / 2;
    }
  }
}

// button
.formworks-btn {
  background: #fff;
  border: $formworks-btn-border solid transparent;
  border-radius: $formworks-btn-border-radius;
  box-shadow: 0px 3px 1px -2px rgba( 0, 0, 0, .2 ),
              0px 2px 2px 0px rgba( 0, 0, 0, .14 ),
              0px 1px 5px 0px rgba( 0, 0, 0, .12 );
  cursor: pointer;
  color: #333;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: map-get( $formworks-btn-size, sm );
  font-weight: 500;
  //line-height: map-get( $formworks-btn-height, sm );
  height: map-get( $formworks-btn-height, sm );
  outline: none;
  padding: 0 map-get( $formworks-btn-padding, sm );
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  @include media(md){
    font-size: map-get( $formworks-btn-size, md );
    //line-height: map-get( $formworks-btn-height, md );
    height: map-get( $formworks-btn-height, md );
    padding: 0 map-get( $formworks-btn-padding, md );
  }
  @include media(lg){
    font-size: map-get( $formworks-btn-size, lg );
    //line-height: map-get( $formworks-btn-height, lg );
    height: map-get( $formworks-btn-height, lg );
    padding: 0 map-get( $formworks-btn-padding, lg );
  }
  // Hover
  &:hover {
    background: #f8f9fa;
    color: inherit;
  }

  // Color Pattern
  @each $name, $value in $formworks-btn-color-array {
    &.#{$name} {
      background: map-get( $value, 'bg' );
      border: $formworks-btn-border solid transparent;
      color: map-get( $value, 'color' );
      &:hover {
        background: multi-array( $formworks-btn-hover-color-array, #{$name}, 'bg' );
        color: multi-array( $formworks-btn-hover-color-array, #{$name}, 'color' );
      }
    }
  }

  // expanded
  &.expanded {
    display: block;
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }

  // outline
  &.outline {
    background: $white-color;
    border: $formworks-btn-border solid $base-color;
    color: $base-color;
    &:hover {
      background: lighten( $base-color, 20% );
      color: $white-color;
    }
  }
}
