@charset 'UTF-8';

// footer
//
footer {
  background: transparent;
  flex: none;
  padding-top: map-get( $footer_padding_top_array, sm );
  width: 100%;
  z-index: map-get( $z_index_array, footer );
  @include media(md) {
    padding-top: map-get( $footer_padding_top_array, md );
  }
  @include media(lg) {
    padding-top: map-get( $footer_padding_top_array, lg );
  }
}



// footer-wrapper
//
.footer-wrapper {
  background: $background_footer;
  position: relative;
  padding: 0 map-get( $footer_container_padding_array, sm );
  @include media(md) {
    padding: 0 map-get( $footer_container_padding_array, md );
  }
  @include media(lg) {
    padding: 0 map-get( $footer_container_padding_array, lg );
  }
  @include width_wrapper( $footer_container_min_width_array, $footer_container_padding_array );
}



// footer-container
//
.footer-container {
  margin: 0 auto;
  @include width_container( $footer_container_width_array );
}



// footer-inner
//
.footer-inner {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-start;
  padding: 2em 0;
}



// footer-inner-left
// footer-inner に内包して使用する
//
.footer-inner-left {
  flex: 0 0 100%;
  width: 100%;
  @include media(md){
    flex: 0 0 50%;
    width: 50%;
  }
}



// footer-inner-right
// footer-inner に内包して使用する
//
.footer-inner-right {
  flex: 0 0 100%;
  width: 100%;
  @include media(md){
    flex: 0 0 50%;
    width: 50%;
    text-align: right;
  }
}



// footer-title
//
.footer-title {
  color: $white-color;
  font-size: 1em;
  font-weight: 700;
  @include media(md) {
    font-size: 1.2em;
  }
  @include media(lg) {
    font-size: 1.5em;
  }
  a {
    color: $body_text_color;
  }
}



.footer-list {
  color: $white-color;
  li {
    margin: .5em 0;
  }
  a {
    color: $white-color;
    transition: opacity $transition;
    &:hover {
      opacity: .5;
    }
  }
  &.right-item {
    @include media(lg){
      text-align: right;
    }
    a {
      padding-left: 1.2em;
      position: relative;
      &::before {
        background: url( /images/icon/icon-pdf.svg ) no-repeat;
        background-size: contain;
        content: '';
        display: block;
        position: absolute;
        width: 1em;
        height: 1em;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
}



.footer-utilities-list {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  line-height: 1;
  margin: 0 -.5em 1em;
  @include media(md){
    justify-content: flex-start;
    flex: 1;
    margin: 0;
  }
  li {
    flex: 0 0 50%;
    padding: .5em 0;
    @include media(md){
      flex: 0 0 auto;
      padding: 0;
      a {
        padding: 0 1em 0 0;
      }
      &:nth-of-type(n+2){
        a {
          border-left: 1px solid $white-color;
          padding: 0 1em 0 1em;
        }

      }
    }
  }
  a {
    color: $white-color;
    font-size: 11px;
    position: relative;
    &.outside {
      &:after {
        content: '\f35d';
        font-family: $icon_font_family;
        color: inherit;
        display: inline-block;
        font-weight: 900;
        margin-left: .325em;
      }
    }
  }
}
