@charset 'UTF-8';

// font size
//
$label_font_size: .75em;



// label 上下の padding
//
$label_padding_vertical_array: (
  sm: .425em,
  md: .5em,
  lg: .4em
) !default;



// label 左右の padding
//
$label_padding_horizontal_array: (
  sm: .6em,
  md: .6em,
  lg: .6em
) !default;



// 角丸
//
$label_border_radius: 0px !default;



// アウトライン時のボーダーの太さ
$label_border: 1px !default;



// Color
//
$label_colors_array: (
  'base'   : ( 'bg' : $base-color,   'color' : #fff ),
  'first'  : ( 'bg' : $first-color,  'color' : #fff ),
  'second' : ( 'bg' : $second-color, 'color' : #fff ),
  'third'  : ( 'bg' : $third-color,  'color' : #fff ),
  'fourth' : ( 'bg' : $fourth-color, 'color' : #fff ),
  'five'   : ( 'bg' : $five-color,   'color' : #fff )
) !default;

// outline
//
$label_outline_colors_array: (
  'base'   : ( 'bg' : #fff, 'color' : $base-color,   'border' : $base-color ),
  'first'  : ( 'bg' : #fff, 'color' : $first-color,  'border' : $first-color ),
  'second' : ( 'bg' : #fff, 'color' : $second-color, 'border' : $second-color ),
  'third'  : ( 'bg' : #fff, 'color' : $third-color,  'border' : $third-color ),
  'fourth' : ( 'bg' : #fff, 'color' : $fourth-color, 'border' : $fourth-color ),
  'five'   : ( 'bg' : #fff, 'color' : $five-color,   'border' : $five-color )
) !default;
