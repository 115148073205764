@charset 'UTF-8';

// burger
//

// border color
//
$burger_border_color: $white-color;



// bar の太さ
//
$burger_border_thickness: 2px;



// bar の長さ
//
$burger_border_width_array: (
  'sm': 24px,
  'md': 24px,
  'lg': 24px
);



// bar 同士の間隔
//
$burger_border_margin_array: (
  'sm': 4px,
  'md': 4px,
  'lg': 4px
);



// burger open 時に付与するクラス名
//
$burger_open_class: '.open-menu';
