@charset 'UTF-8';



// Video
.movie-wrapper {
  position: relative;
  overflow: hidden;
  width: 100%;
  &:before {
    content: '';
    display: block;
    padding-top: 56.25%;
  }
  video {
    background: url('/images/top/no-video.jpg') no-repeat;
    background-size: cover;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
}

.video-overlay {
  background-image: radial-gradient( $gray-600 20%, transparent 30% ),
                    radial-gradient( $gray-600 20%, transparent 30% );
  background-size: 2px 2px;
  background-position: 0 0, 1px 1px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  @include media(lg){
    background-size: 4px 4px;
    background-position: 0 0, 2px 2px;
  }
}

.on-video-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate( -50%, -50% );
  z-index: 1;
  @include media(lg){
    img {
      width: 32vw;
      max-width: none;
    }

  }
}



// Slider
//
.top-slider-hero {
  position: relative;
  height: 80vh;
  z-index: 0;
  @include media(lg){
    height: 90vh;
  }
}

// images
.top-slider-images {
  background-size: cover;
  background-position: center top;
  background-repeat: no-repeat;
  backface-visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  &._image-01 {
    background-image: url( ../../../images/slider/image1.jpeg );
  }
  &._image-02 {
    background-image: url( ../../../images/slider/image2.jpeg );
  }
  &._image-03 {
    background-image: url( ../../../images/slider/image3.jpeg );
  }
}

// text
.top-slider-hero-inner {
  position: absolute;
  top: 20%;
  left: 50%;
  text-align: center;
  transform: translateX(-50%);
  width: 100%;
  z-index: 2;
  @include media(lg){
    top: 20%;
    left: -5%;
    transform: translateX(0);
    width: 90vw;
  }
}

.slider-inner-text {
  color: $white-color;
  font-family: 'Poppins', sans-serif;
  font-size: 38px;
  font-weight: 700;
  letter-spacing: -0.5px;
  @include media(lg){
    font-size: 64px;
    line-height: 2;
  }
}



// 4つの取扱品目
//
.top-products-wrapper {
  position: relative;
  margin-top: 32px;
  @include media(lg){
    margin-top: 44px;
  }
}

.top-products-inner {
  display: flex;
  flex-flow: row wrap;
  margin: -8px;
}

.top-products-item {
  flex: 0 0 50%;
  padding: 8px;
  @include media(lg){
    flex: 0 0 25%;
  }
  &:hover {
    .item-card {
      background-size: 120px 120px;
    }
  }
}

// CARD
.item-card {
  background-color: $white-color;
  background-image: url( ../../../images/top/product-icon-ka.png );
  background-position: bottom 10px right 10px;
  background-repeat: no-repeat;
  background-size: 100px 100px;
  box-shadow: 0 1px 6px rgba(0,0,0,0.1);
  height: 100%;
  position: relative;
  transition: background-size .2s;
  &._kokunai {
    background-image: url( ../../../images/top/product-icon-ko.png );
  }
  &._dantai {
    background-image: url( ../../../images/top/product-icon-da.png );
  }
  &._sonota {
    background-image: url( ../../../images/top/product-icon-so.png );
  }
}

.item-image { // 画像
  width: 100%;
  height: auto;
}

.item-body {
  padding: 12px;
  .item-heading {
    color: $base-color;
    font-size: 16px;
    font-weight: 700;
    margin-top: 12px;
    @include media(lg){
      font-size: 18px;
    }
  }
  p {
    color: $body-text-color;
    font-size: 12px;
    margin-top: 8px;
    @include media(lg){
      font-size: 14px;
    }
  }
}



// お知らせ
//
.top-info-wrapper {
  position: relative;
  margin-top: 32px;
  margin-bottom: 32px;
  @include media(lg){
    margin-top: 44px;
    margin-bottom: 44px;
  }
}

.top-info-container {
  background: $white-color;
  box-shadow: 0 1px 6px rgba(0,0,0,0.1);
  padding: 2em;
}

.top-info-list-upper {
  display: flex;
  align-items: center;
  font-size: 1.125em;
  line-height: 1;
  margin-bottom: .5em;
}

ul.top-info-list {
  li:nth-of-type(n+2) {
    border-top: dotted 1px #ccc;
  }
  a {
    color: $body-text-color;
    display: block;
    padding: 1em .5em;
    transition: background $transition;
    @include media(md) {
      padding: 1em 1.5em;
    }
    &:hover{
      background: #f5fdfe;
    }
    &.no-link {
      pointer-events: none;
    }
  }
}



// Banner carousel
//

.banner-carousel-wrapper {
  overflow: hidden;
  padding: 16px 0;
  @include media(lg){
    padding: 30px 0;
  }
}

.banner-carousel {
  .slick-list {
    margin: 0 10px;
  }
  .slick-slide {
    margin: 0 6px;
  }
}

// Banner
// 一つだけのバナー
.banner-lower-wrapper {
  display: block;
  margin: 2em auto;
  width: 260px;
}
