@charset 'UTF-8';

// IE11 判定
.ie-alert-wrapper {
  background: transparent;
  padding: 0 1em;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: auto;
  z-index: 999;
  transition: transform .5s;
  &.invisible {
    transform: translateY(100%);
  }
}

.ie-alert-container {
  background: #fff;
  border-top: 4px solid red;
  box-shadow: 0px 0px 20px -6px #b6b6b6;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  padding: 2em 1em;
  @include media(md){
    padding: 2em;
    max-width: 768px;
  }
  @include media(lg){
    max-width: 1164px;
  }
}

.ie-alert-icon {
  flex: 0 0 52px;
  margin-right: 1em;
  @include media(md){
    flex: 0 0 64px;
  }
}

.ie-alert-heading {
  color: inherit;
  font-size: 1em;
  font-weight: 700;
  span {
    display: block;
    font-size: .875em;
    font-weight: 500;
    margin-top: .5em;
  }
}

.ie-alert-close {
  cursor: pointer;
  margin-left: 1em;
  img {
    width: 2em;
  }
  &:hover {
    img {
      opacity: .7;
    }
  }
}
