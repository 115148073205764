@charset 'UTF-8';

// border の太さ
//
$alert_border: 1px;



// border-radius
//
$alert_border_radius: 0px;



// box-shadow
//
$alert_box_shadow: 0 2px 4px 0 rgba( 0,0,0, .12 );



// background-color, color
//
$alert_color_array: (
  'base'   : ( 'bg' : $base_color,   'color' : #fff ),
  'first'  : ( 'bg' : $first_color,  'color' : #fff ),
  'second' : ( 'bg' : $second_color, 'color' : #fff ),
  'third'  : ( 'bg' : $third_color,  'color' : #fff ),
  'fourth' : ( 'bg' : $fourth_color, 'color' : #fff ),
  'five'   : ( 'bg' : $five_color,   'color' : #fff )
) !default;



// outline
// background-color, color, border-color
//
$alert_outline_color_array: (
  'base'   : ( 'bg' : #fff, 'color' : $base_color,   'border' : $base_color ),
  'first'  : ( 'bg' : #fff, 'color' : $first_color,  'border' : $first_color ),
  'second' : ( 'bg' : #fff, 'color' : $second_color, 'border' : $second_color ),
  'third'  : ( 'bg' : #fff, 'color' : $third_color,  'border' : $third_color ),
  'fourth' : ( 'bg' : #fff, 'color' : $fourth_color, 'border' : $fourth_color ),
  'five'   : ( 'bg' : #fff, 'color' : $five_color,   'border' : $five_color )
) !default;
