@charset 'UTF-8';

// heading initialize
//
h1, h2, h3, h4, h5, h6 {
  font-family: $TBUD-G-E;
  font-style: normal;
  font-weight: bold;
  text-rendering: optimizeLegibility;
  margin-top: 0;
  margin-bottom: .4em;
  line-height: 1.4;
}



// heading
//
h1 {
  font-size: $h1-font-size;
}

h2 {
  font-size: $h2-font-size;
  &.info-heading {
    color: $base-color;
    display: flex;
    align-items: center;
    flex-flow: row wrap;
    font-size: 26px;
    font-weight: normal;
    letter-spacing: 0.1em;
    border-top: solid 1px $base-color;
    border-bottom: solid 1px $base-color;
    padding: .25em .25em .25em 0;
    i {
      margin-right: .2em;
    }
    span {
      display: inline-block;
    }
    .sub {
      font-size: .6em;
      margin-left: 1em;
    }
  }
  // 下層ページ
  &.local-headline {
    font-size: 24px;
    margin-bottom: 1em;
    @include media(lg){
      font-size: 32px;
    }
  }
}

.sub-headline {
  color: $gray-500;
  font-size: 20px;
  @include media(lg){
    font-size: 26px;
  }
}


h3 {
  font-size: $h3-font-size;
  &.local-headline {
    margin: 0;
    .sub {
      color: $gray-600;
      display: block;
      font-size: .725em;
      &._right {
        margin-right: 0;
      }
      &._left {
        margin-left: 0;
      }
    }
    @include media(lg){
      .sub {
        display: inline-block;
        &._right {
          margin-right: .4em;
        }
        &._left {
          margin-left: .4em;
        }
      }
    }
  }
  &.form-confirm {
    color: $base-color;
    margin-bottom: 36px;
    text-align: center;
  }
}

h4 {
  font-size: $h4-font-size;
}

h5 {
  font-size: $h5-font-size;
}

h6 {
  font-size: $h6-font-size;
}



// paragraph
//
p {
  font-size: inherit;
  letter-spacing: map-get( $p_letter_spacing_array, sm );
  line-height: map-get( $p_line_height_array, sm );
  text-rendering: optimizeLegibility;
  @include media(md) {
    letter-spacing: map-get( $p_letter_spacing_array, md );
    line-height: map-get( $p_line_height_array, md );
  }
  @include media(lg) {
    letter-spacing: map-get( $p_letter_spacing_array, lg );
    line-height: map-get( $p_line_height_array, lg );
  }
}



// フォントファミリーを日本語に限定する
//
.mbf {
  font-family: -apple-system, BlinkMacSystemFont, 'Hiragino Sans', 'Original Yu Gothic', 'Yu Gothic', sans-serif !important;
}



// 主に paragraph を段落として使用しない場合に margin-bottom を打ち消す
//
.phrase {
  margin-bottom: 0 !important;
}



// イタリック
//
i {
  font-style: italic;
  line-height: inherit;
}



// ボールド
//
strong,
em,
b {
  font-style: normal;
  font-weight: bold;
  line-height: inherit;
}


// キャプション
figcaption {
  color: $gray-600;
  margin-top: .4em;
}



// code
//
pre {
  background: $gray-100;
  padding: 1em;
  white-space: pre-wrap;
  word-wrap: break-word;
}

code {
  background: transparent;
  color: $gray-700;
  font-family: 'SourceHanCodeJP-Regular', monospace;
  font-size: .8em;
  font-weight: bold;
  vertical-align: initial;
}
