@charset 'UTF-8';

// variable
//
$pagetop_active_class_name: '.active';



.pagetop {
  background: rgba( $gray-800, .9 );
  //border-radius: 50%;
  color: $white-color;
  cursor: pointer;
  display: block;
  font-size: 14px;
  padding: .4rem;
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  opacity: 0;
  text-align: center;
  text-decoration: none;
  visibility: hidden;
  transition: all $transition;
  width: 44px;
  height: 44px;
  z-index: 999;
  &:after {
    content: '';
    display: inline-block;
    width: 18px;
    height: 18px;
    border-right: 3px solid #fff;
    border-top: 3px solid #fff;
    transform: rotate(-45deg);
    margin-top: .9em;
    transition: transform .3s ease-out;
    /*
    @include media(md) {
      width: 20px;
      height: 20px;
      margin-top: 1em;
    }
    @include media(lg) {
      width: 24px;
      height: 24px;
      margin-top: 1.325em;
    }
    */
  }
  &:hover {
    background: rgba( lighten( $black-color, 10% ), .9);
  }

  &#{$pagetop_active_class_name} {
    display: block;
    opacity: 1;
    visibility: visible;
  }
/*
  @include media(md) {
    width: 50px;
    height: 50px;
  }

  @include media(lg) {
    bottom: 6rem;
    right: 3rem;
    width: 60px;
    height: 60px;
  }
*/
}
