@charset 'UTF-8';

// 下層ページ
// ----------------------------



// 下層ページの見出し
.local-hero-wrapper {
  background: $second_color;
}

.local-heading-wrapper {
  padding: 4em 0;
}

.local-heading {
  display: flex;
  align-items: flex-end;
  // アイコン
  .local-icon {
    margin-right: 12px;
    min-width: 5.2rem;
    img {
      width: 5.2rem;
      height: auto;
      @include media(lg){
        width: 7.4rem;
      }
    }
  }
  // text
  h1 {
    font-size: 28px;
    line-height: 1.2;
    margin: 0;
    .sub {
      font-size: 16px;
    }
    @include media(lg){
      font-size: 40px;
      .sub {
        font-size: 22px;
      }
    }
  }
}

// h3
.local-heading-sub-wrapper {
  position: relative;
  margin-bottom: 2em;
  &.invisible {
    opacity: 0;
  }
}

.local-heading-sub {
  display: flex;
  align-items: flex-end;
}

.local-icon-sub {
  margin-right: 12px;
  flex: 0 0 5.2rem;
  @include media(lg){
    flex: 0 0 7.4rem;
  }
}

.local-headline-border {
  background: $fourth-color;
  margin-top: 10px;
  width: 100%;
  height: 4px;
}



// access
// google map
.google-map-wrapper {
  margin-bottom: 1.2em;
  width: 100%;
  height: 280px;
  iframe {
    width: 100%;
    height: 100%;
  }
}



// Local
//

// 国内出張・貸会議室
.meeting-image-copy {
  position: absolute;
  top: 50%;
  left: 12px;
  transform: translateY(-50%);
  @include media(md){
    left: 36px;
  }
  @include media(md){
    left: 62px;
  }
  img {
    width: 40vw;
    @include media(md){
      width: 30vw;
    }
    @include media(lg){
      max-width: 330px;
    }
  }
}

// アクセス
.access-train {
  background: $white-color;
  box-shadow: 0 .125rem .25rem rgba( 0,0,0, .075 );
  border-radius: 6px;
  display: flex;
  align-items: center;
  margin-top: 1em;
  padding: 1em;

  .image {
    display: block;
    margin-right: 1em;
    width: 13vw;
    @include media(md){
      margin-right: 1.5em;
      width: 52px;
    }
  }

  p {
    line-height: 1.6;
    margin-top: 0;
    font-weight: 500;
  }

  .md-br-access {
    display: inline;
    @include media(md){
      display: block;
    }
  }
}

// 採用情報
.recruit-image-copy {
  position: absolute;
  top: 24px;
  right: 12px;
  @include media(md){
    top: 36px;
  }
  @include media(md){
    top: 68px;
  }

  img {
    width: 44vw;
    @include media(md){
      width: 43vw;
    }
    @include media(lg){
      max-width: 470px;
    }
  }
}

// 貸切バス
.charter-bus-table-wrapper {
  background: $white-color;
  box-shadow: 0 .125rem .25rem rgba( 0,0,0, .075 );
  margin-top: 2em;
  padding: 1em;
}

.charter-bus-table-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.charter-bus-table,
.charter-bus-seat {
  flex: 0 0 100%;
  @include media(md){
    flex: 0 0 50%;
  }
}

.charter-bus-table {
  display: flex;
}

.bus-images,
.bus-list {
  flex: 0 0 50%;
}

.bus-images{
  position: relative;
}

.bus-label {
  background: $base-color;
  color: $white-color;
  display: inline-block;
  padding: .4em 1em;
  font-weight: 700;
  position: absolute;
  bottom: 0;
  left: 0;
  &.__large {
    left: auto;
    right: 0;
  }
}

.bus-list {
  padding-left: 1em;
}

ul.charter-bus-detail {
  border: 1px solid $gray-300;
  font-size: 12px;
  line-height: 1.6;
  padding: 1em;
  li {
    &:nth-of-type(n+4){
      color: $third-color;
    }
  }

}

.charter-bus-seat {
  padding-top: 1em;
  @include media(md){
    padding-top: 0;
    padding-left: 1em;
  }
  img {
    width: 100%;
    max-width: none;
  }
}

// coming soon
.local-coming-soon {
  color: $white-color;
  font-size: 2em;
  font-weight: 900;
  position: absolute;
  bottom: 1em;
  left: 1em;
  @include media(md){
    font-size: 3em;
  }
  @include media(lg){
    bottom: 2em;
  }
}

// 旅行業約款
.travel-agent-contract-list {
  li {
    &:nth-of-type(n+2){
      margin-top: .5em;
    }
  }
  a {
    color: $gray-dark;
    padding-right: 1.5em;
    position: relative;
    transition: opacity $transition;
    &:hover {
      opacity: .7;
    }

    &::after {
      background: url( /images/icon/icon-pdf-gray.svg ) no-repeat;
      background-size: contain;
      content: '';
      display: block;
      position: absolute;
      width: 1em;
      height: 1em;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}
