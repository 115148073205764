@charset 'UTF-8';

// header
//
header {
  background: transparent;
  padding-bottom: map-get( $header_padding_bottom_array, sm );
  width: 100%;
  z-index: map-get( $z_index_array, header );
  @include media(md) {
    padding-bottom: map-get( $header_padding_bottom_array, md );
  }
  @include media(lg) {
    padding-bottom: map-get( $header_padding_bottom_array, lg );
  }
}



// header-wrapper
//
.header-wrapper {
  background: $background_header;
  position: relative;
  padding: 0 map-get( $header_container_padding_array, sm );
  @include media(md) {
    padding: 0 map-get( $header_container_padding_array, md );
  }
  @include media(lg) {
    padding: 0 map-get( $header_container_padding_array, lg );
  }
  @include width_wrapper( $header_container_min_width_array, $header_container_padding_array );
}



// header-container
//
.header-container {
  margin: 0 auto;
  @include width_container( $header_container_width_array );
}



// header-inner
//
.header-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: map-get( $header_height_array, sm );
  @include media(md) {
    height: map-get( $header_height_array, md );
  }
  @include media(lg) {
    height: map-get( $header_height_array, lg );
  }
}



// header-inner-left
// header-inner に内包して使用する
//
.header-inner-left {
  flex: 0 0 auto;
}



// header-inner-right
// header-inner に内包して使用する
//
.header-inner-right {
  display: flex;
  align-items: center;
  flex: 0 0 auto;
  margin-left: auto;
}



// header-title
//
.header-title {
  img {
    display: block;
    width: 9rem;
    height: auto;
    @include media(md) {
      width: 9rem;
    }
    @include media(lg) {
      width: 26rem;
    }
  }
}



// Phone icon
//
.phone-wrapper {
  display: flex;
  justify-content: space-between;
  margin-right: 24px;
  img {
    width: auto;
    height: 32px;
  }
  @include media(lg){
    display: none;
  }
}



// PC 社名表示
//
.header-co-wrapper {
  display: none;
  @include media(lg){
    display: block;
  }
}

.header-co-name {
  color: $white-color;
  font-size: 1.2em;
  font-weight: 700;
}

.header-co-text {
  color: $second_color;
  font-weight: 700;
}

.header-co-tel {
  color: $white-color;
  font-size: 1.2em;
}
